const searchForm = ((window, $) => {
	const init = () => {
		const searchWindow = $('[rel="search-window"]'),
			openWindow = $('[rel="search-open"]'),
			windowContent = $('.search-window-content'),
			closeButton = $('[rel="search-close"]');
		openWindow.on('click', () => {
			searchWindow.fadeIn(400);
			windowContent
				.hide(0)
				.delay(400)
				.fadeIn(600, function() {
					$('.search-form-input')
						.val('')
						.focus();
				});
		});
		closeButton.on('click', () => {
			windowContent.fadeOut(400);
			searchWindow.delay(400).fadeOut(600);
		});
	};

	$(document).ready(function() {
		init();
	});
})(window, jQuery);

export default searchForm;
